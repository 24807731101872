import { FeedScope } from '@/__generated__/graphql.ts';
import { gql } from '@/__generated__/index.ts';
import { preloadQuery } from '@/apollo/apollo-client.ts';
import { hasFeedPosition } from '@/components/feed/use-restore-feed.ts';
import { paths } from '@/routes-utils/paths.ts';
import { getFeedType } from './feed-type.ts';

export const FollowingFeedQuery = gql(`
  query FollowingFeedQuery($limit: Int!, $nextToken: String, $scope: FeedScope!) {
    feed(limit: $limit, nextToken: $nextToken, scope: $scope) {
      items {
        ... on RegularPost {
          ...PostCardFragment
        }
        ... on RePost {
         ...RepostCardFragment
        }
        ... on TournamentPost {
         ...TournamentPostCardFragment
        }
        ... on StreamPost {
         ...StreamPostCardFragment
        }
      }
      nextToken
    }
  }
`);

export const homeLoader = () => {
  const feedType = getFeedType();

  let scope;

  switch (feedType) {
    case FeedScope.FeedFollowing:
      scope = FeedScope.FeedFollowing;
      break;
    case FeedScope.FeedViral:
      scope = FeedScope.FeedViral;
      break;
    case FeedScope.FeedStream:
      scope = FeedScope.FeedStream;
      break;
    default:
      scope = FeedScope.FeedFollowing;
  }

  return {
    queryRef: preloadQuery(FollowingFeedQuery, {
      variables: {
        limit: 20,
        nextToken: null,
        scope,
      },
      fetchPolicy: hasFeedPosition(paths.default)
        ? 'cache-first'
        : 'cache-and-network',
    }),
    feedType,
  };
};

export type HomeLoaderData = ReturnType<typeof homeLoader>;
